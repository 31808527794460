<template>
    <button class="p-3 primary">
        <slot></slot>
    </button>
</template>

<script setup>

</script>

<style scoped>
    button{
        background: var(--green) !important;
        border-color: none;
        text-transform: uppercase;
        font-size: 14px !important;
        border: none;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 1;
        color: #fff;
        width: 100%;
    }
    .btn-primary, .btn-primary:hover {
        border-color: var(--green);
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
</style>
